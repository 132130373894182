import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';
import styles from '../styles/Phrases.module.css';

function Phrases() {
  const { state } = useContext(GameContext);
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.phrase}>{state.isStarted ? state.phrase : ''}</h2>
    </div>
  );
}

export default Phrases;

import Runner from '../core/GameLogic';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GameContext } from '../providers/GameProvider';
import { useContext } from 'react';
import Phrases from './Phrases';
import Grade from './Grade';
import { LanguageContext } from '../providers/LanguageProvider';

function Game() {
  const { actions, state } = useContext(GameContext);
  const { vocabulary } = useContext(LanguageContext);
  const clickRef = useRef(null);
  const GameInstance = useRef(null);
  const IS_IOS =
    window.navigator.userAgent.indexOf('UIWebViewForStaticFileContent') > -1;

  const getTimeStamp = () => {
    return new Date().getTime();
  };

  useEffect(() => {
    if (state.gender || GameInstance.current) {
      GameInstance.current = new Runner(
        '.game-wrapper',
        null,
        actions,
        state,
        state.gender,
        vocabulary
      );
      const currentTime = getTimeStamp();

      return () => {
        const now = new Date().getTime();
        const deltaTime = now - currentTime;
        actions.jump();

        if (!state.isStarted) {
          if (deltaTime > 500) {
            actions.setRestart(GameInstance.current);
            GameInstance.current.restart();
          }
        }
      };
    }
  }, [state.gender, state.isStarted]);

  const handleClick = useCallback((e) => {
    actions.jump();
  }, GameInstance.current);

  useEffect(() => {
    if (clickRef.current && state.isMobile) {
      clickRef.current.addEventListener('click', handleClick);
      actions.setGameContainer(clickRef);
    }
  }, [clickRef.current, state.isStarted]);

  return (
    <div className='layout' ref={clickRef}>
      <div className='sky'>
        <Grade />
      </div>
      <div
        className={
          state.isStarted ? 'game-wrapper' : 'game-wrapper hide_game'
        }></div>
      <div className='ground'>
        <Phrases />
      </div>
    </div>
  );
}

export default Game;

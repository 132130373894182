import CACTUS_LARGE_1x from '../assets/sprites/1x/obstacles_large.png';
import CACTUS_SMALL_1x from '../assets/sprites/1x/obstacles_small.png';
import CLOUD_1x from '../assets/sprites/1x/cloud.png';
import HORIZON_1x from '../assets/sprites/1x/grass_large.png';
import RESTART_1x from '../assets/sprites/1x/restart.png';
import TEXT_SPRITE_1x from '../assets/sprites/1x/text_sprite.png';
import MALE_1x from '../assets/sprites/1x/male.png';
import FEMALE_1x from '../assets/sprites/1x/female.png';

import CACTUS_LARGE_2x from '../assets/sprites/2x/obstacles_large.png';
import CACTUS_SMALL_2x from '../assets/sprites/2x/obstacles_small.png';
import CLOUD_2x from '../assets/sprites/2x/cloud.png';
import HORIZON_2x from '../assets/sprites/2x/grass_large.png';
import RESTART_2x from '../assets/sprites/2x/restart.png';
import TEXT_SPRITE_2x from '../assets/sprites/2x/text_sprite.png';
import MALE_2x from '../assets/sprites/2x/male.png';
import FEMALE_2x from '../assets/sprites/2x/female.png';

export const Assets = {
  LDPI: [
    {
      name: 'CACTUS_LARGE',
      id: '1x-obstacle-large',
      base64: CACTUS_LARGE_1x,
    },
    {
      name: 'CACTUS_SMALL',
      id: '1x-obstacle-small',
      base64: CACTUS_SMALL_1x,
    },
    {
      name: 'CLOUD',
      id: '1x-cloud',
      base64: CLOUD_1x,
    },
    {
      name: 'HORIZON',
      id: '1x-horizon',
      base64: HORIZON_1x,
    },
    {
      name: 'RESTART',
      id: '1x-restart',
      base64: RESTART_1x,
    },
    {
      name: 'TEXT_SPRITE',
      id: '1x-text',
      base64: TEXT_SPRITE_1x,
    },
    {
      name: 'TREX',
      id: '1x-trex',
      male: MALE_1x,
      female: FEMALE_1x,
    },
  ],
  HDPI: [
    {
      name: 'CACTUS_LARGE',
      id: '1x-obstacle-large',
      base64: CACTUS_LARGE_2x,
    },
    {
      name: 'CACTUS_SMALL',
      id: '1x-obstacle-small',
      base64: CACTUS_SMALL_2x,
    },
    {
      name: 'CLOUD',
      id: '1x-cloud',
      base64: CLOUD_2x,
    },
    {
      name: 'HORIZON',
      id: '1x-horizon',
      base64: HORIZON_2x,
    },
    {
      name: 'RESTART',
      id: '1x-restart',
      base64: RESTART_2x,
    },
    {
      name: 'TEXT_SPRITE',
      id: '1x-text',
      base64: TEXT_SPRITE_2x,
    },
    {
      name: 'TREX',
      id: '1x-trex',
      male: MALE_2x,
      female: FEMALE_2x,
    },
  ],
};

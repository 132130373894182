import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';

function EndGameWrapper({ children }) {
  const {
    state: { isStarted },
  } = useContext(GameContext);
  return (
    !isStarted && (
      <div className='endGame-wrapper'>
        <div className='limiter'>{children}</div>
      </div>
    )
  );
}

export default EndGameWrapper;

import React, { useState } from 'react';
import { Languages } from '../core/Language';

export const LanguageContext = React.createContext();

export function LanguageProvider(props) {
  const [vocabulary, setLanguage] = useState(null);

  const handleLanguageSelect = (lang) => {
    setLanguage(Languages[lang]);
  };

  return (
    <LanguageContext.Provider
      value={{
        vocabulary,
        actions: {
          handleLanguageSelect,
        },
      }}>
      {props.children}
    </LanguageContext.Provider>
  );
}

import { useContext } from 'react';
import { LanguageContext } from '../providers/LanguageProvider';
import styles from '../styles/SelectLanguage.module.css';

function SelectLanguage() {
  const { vocabulary, actions } = useContext(LanguageContext);
  return (
    !vocabulary && (
      <div className={styles.wrapper}>
        <h3 className={styles.selectPhrase}>
          Выбери язык / Choose the language
        </h3>
        <div className={styles.langs}>
          <div
            className={styles.lang}
            onClick={() => actions.handleLanguageSelect('ru')}>
            RU
          </div>
          <div
            className={styles.lang}
            onClick={() => actions.handleLanguageSelect('en')}>
            EN
          </div>
        </div>
      </div>
    )
  );
}

export default SelectLanguage;

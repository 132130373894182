import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';
import { LanguageContext } from '../providers/LanguageProvider';
import styles from '../styles/SelectGender.module.css';

function SelectGender() {
  const { state, actions } = useContext(GameContext);
  const { vocabulary } = useContext(LanguageContext);

  return (
    !state.gender &&
    vocabulary !== null && (
      <div className={styles.wrapper}>
        <h3 className={styles.selectPhrase}>{vocabulary.selectGenderPhrase}</h3>
        <div className={styles.genders}>
          <div
            className={styles.gender}
            onClick={() => actions.setGender('female')}>
            <img
              className={styles.female}
              src='/female_hd.png'
              alt='женская версия'
            />
          </div>
          <div
            className={styles.gender}
            onClick={() => actions.setGender('male')}>
            <img
              className={styles.male}
              src='/male_hd.png'
              alt='мужская версия'
            />
          </div>
        </div>
      </div>
    )
  );
}

export default SelectGender;

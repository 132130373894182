import './styles/App.css';
import Game from './components/Game';
import Diploma from './components/Diploma';
import Start from './components/Start';
import { GameProvider } from './providers/GameProvider';
import SelectGender from './components/SelectGender';
import DarkBackground from './components/DarkBackground';
import SelectLanguage from './components/SelectLanguage';
import { LanguageProvider } from './providers/LanguageProvider';
import EndGameWrapper from './components/EndGameWrapper';

function App() {
  //fix adaptive
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', appHeight);
  appHeight();

  return (
    <div className='App'>
      <LanguageProvider>
        <GameProvider>
          <DarkBackground />
          <SelectLanguage />
          <SelectGender />
          <EndGameWrapper>
            <Diploma />
            <Start />
          </EndGameWrapper>
          <Game />
        </GameProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;

import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';
import styles from '../styles/Grade.module.css';

function Grade() {
  const { state } = useContext(GameContext);
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.grade}>{state.isStarted ? state.grade : ''}</h2>
    </div>
  );
}

export default Grade;

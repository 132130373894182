import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';
import styles from '../styles/DarkBackground.module.css';

function DarkBackground() {
  const { state, actions } = useContext(GameContext);

  const handleClick = (e) => {
    if (state.score) {
      actions.startGame(true);
      state.restart.restart();
    } else if (actions.jump && !state.isStarted) {
      actions.jump();
    }
  };
  return (
    !state.isStarted && (
      <div
        onClick={state.isMobile && handleClick}
        className={styles.wrapper}></div>
    )
  );
}

export default DarkBackground;

export const Languages = {
  ru: {
    chooseCharacter: 'Выбери персонажа',
    playPhrase: 'ИГРАТЬ',
    playSubPhrase: 'Нажми на кнопку SPACE',
    playSubPhraseMobile: 'Нажми на экран',
    restartPhrase: 'ЕЩЁ ОДНО ВЫСШЕЕ',
    indexHirsh: 'Индекс Хирша',
    diplomaPhrase: 'ДИПЛОМ',
    selectGenderPhrase: 'Выбери персонажа',
    diploma: 'ДИПЛОМ',
    grades: {
      student: 'Студент',
      bachelor: 'Бакалавр',
      magistr: 'Магистр',
      aspirant: 'Аспирант',
      candidat: 'Кандидат наук',
      doctor: 'Доктор наук',
    },
    morphGrades: {
      student: 'О неполном высшем образовании',
      bachelor: 'Бакалавра',
      magistr: 'Магистра',
      aspirant: 'Аспиранта',
      candidat: 'Кандидата наук',
      doctor: 'Доктора наук',
    },
    Obstacles: {
      Студент: [
        'Экзамены',
        'Стереотипы',
        'Страх выступлений',
        'Неуверенность',
        'Неудача',
        'Нет идей',
        'Дедлайны',
        'Нехайпово',
        'Непонятно',
        'Сложно',
        'Нет публикаций',
        'Нет опыта',
      ],
      Бакалавр: [
        'Экзамены',
        'Стереотипы',
        'Страх выступлений',
        'Неуверенность',
        'Нет идей',
        'Дедлайны',
        'Нехайпово',
        'Сложно',
        'Reject статьи',
        'Weak accept статьи',
        'Занятой научрук',
        'Нет публикаций',
        'Нет опыта',
        'Strong reject',
        '3rd reviewer',
        'Неудача',
      ],
      Магистр: [
        'Экзамены',
        'Отчеты',
        'Рецензии',
        'Нет идей',
        'Дедлайны',
        'Мало времени',
        'Слишком широкая тема',
        'Слишком узкая тема',
        'Непрактично',
        'Нефундаментально',
        'Невозможно внедрить',
        'Не хватает публикаций',
        'Не хватает опыта',
        'Неактуально',
        'Отказ в гранте',
        'Нет финансов',
        'Strong reject',
        '3rd reviewer',
        'Reject статьи',
        'Weak accept статьи',
        'Занятой научрук',
        '5 new people in paper',
        'Конкуренция',
      ],
      Аспирант: [
        'Экзамены',
        'Отчеты',
        'Рецензии',
        'Нет идей',
        'Дедлайны',
        'Мало времени',
        'Слишком широкая тема',
        'Слишком узкая тема',
        'Непрактично',
        'Нефундаментально',
        'Невозможно внедрить',
        'Не хватает публикаций',
        'Не хватает опыта',
        'Неактуально',
        'Отказ в гранте',
        'Нет финансов',
        'Strong reject',
        '3rd reviewer',
        'Reject статьи',
        'Weak accept статьи',
        'Занятой научрук',
        '5 new people in paper',
        'Конкуренция',
        'Нет ассистентов',
        'Нет команды',
        'Не коммерциализировать',
        'Надоедливые журналисты',
      ],
      'Кандидат наук': [
        'Экзамены',
        'Отчеты',
        'Рецензии',
        '3rd reviewer',
        'Отказ в гранте',
        'Нет финансов',
        'Reject статьи',
        'Нет идей',
        'Мало времени',
      ],
      'Доктор наук': ['Отчеты', 'Рецензии', 'Мало времени'],
    },
  },

  en: {
    chooseCharacter: 'Choose your character',
    playPhrase: 'PLAY',
    playSubPhrase: 'press SPACE button',
    playSubPhraseMobile: 'Tap on the screen',
    restartPhrase: 'GO BACK FOR A SECOND DEGREE',
    indexHirsh: 'Hirsch Index',
    diplomaPhrase: 'DIPLOMA',
    selectGenderPhrase: 'Choose character',
    grades: {
      student: 'High school diploma',
      bachelor: 'Bachelor`s degree',
      magistr: 'Master’s degree',
      aspirant: 'PhD degree',
      candidat: 'Postdoc certificate',
      doctor: 'DSc degree',
    },
    morphGrades: {
      student: 'High school diploma',
      bachelor: 'Bachelor`s degree',
      magistr: 'Master’s degree',
      aspirant: 'PhD degree',
      candidat: 'Postdoc certificate',
      doctor: 'DSc degree',
    },
    Obstacles: {
      'High school diploma': [
        'Exams',
        'Stereotypes',
        'Stage fright',
        'Self-doubt',
        'Failure',
        'No ideas',
        'Deadlines',
        'Untrendy',
        'Confusion',
        'Difficulties',
        'No publications',
        'No experience',
      ],
      'Bachelor`s degree': [
        'Exams',
        'Stereotypes',
        'Stage fright',
        'Self-doubt',
        'Failure',
        'No ideas',
        'Deadlines',
        'Untrendy',
        'Difficulties',
        'Article rejected',
        'Weak accept',
        'Busy supervisor',
        'No experience',
        'Reports',
        'Strong reject',
        '3rd reviewer',
        'Failure',
      ],
      'Master’s degree': [
        'Exams',
        'Reports',
        'Reviews',
        'No ideas',
        'Deadlines',
        'Not enough time',
        'Topic too broad',
        'Topic too narrow',
        'Impractical',
        'Non-fundamental',
        'Unadaptable',
        'Need more publications',
        'Need more experience',
        'Irrelevant',
        'Grant rejected',
        'No funding',
        'Strong reject',
        '3rd reviewer',
        'Articles rejected',
        'Weak accept',
        'Busy supervisor',
        '5 new people in paper',
        'Competitors',
      ],
      'PhD degree': [
        'Exams',
        'Reports',
        'Reviews',
        'No ideas',
        'Deadlines',
        'Not enough time',
        'Topic too broad',
        'Topic too narrow',
        'Impractical',
        'Non-fundamental',
        'Unadaptable',
        'Need more publications',
        'Need more experience',
        'Irrelevant',
        'Grant rejected',
        'No funding',
        'Strong reject',
        '3rd reviewer',
        'Articles rejected',
        'Weak accept',
        'Busy supervisor',
        '5 new people in paper',
        'Competitors',
        'No assistants',
        'No team',
        'Unmarketable',
        'Annoying journalists',
      ],
      'Postdoc certificate': [
        'Exams',
        'Reports',
        'Reviews',
        '3rd reviewer',
        'Grant rejected',
        'No funding',
        'Articles rejected',
        'No ideas',
        'Not enough time',
      ],
      'DSc degree': ['Reports', 'Reviews', 'Not enough time'],
    },
  },
};

import { useContext } from 'react';
import { GameContext } from '../providers/GameProvider';
import { LanguageContext } from '../providers/LanguageProvider';
import styles from '../styles/Start.module.css';

function Start() {
  const { state, actions } = useContext(GameContext);
  const { vocabulary } = useContext(LanguageContext);
  const IS_IOS =
    window.navigator.userAgent.indexOf('UIWebViewForStaticFileContent') > -1;

  const getTimeStamp = () => {
    return new Date().getTime();
  };
  const currentTime = getTimeStamp();

  const handleStartGame = () => {
    const now = new Date().getTime();
    const deltaTime = now - currentTime;
    if (state.isMobile && deltaTime > 500) actions.startGame(true);
  };

  return (
    !state.isStarted &&
    state.gender && (
      <div className={styles.start} onClick={handleStartGame}>
        <div className={styles.wrapper}>
          <h1 className={styles.playPhrase}>
            {!state.score ? vocabulary.playPhrase : vocabulary.restartPhrase}
          </h1>
          <span>
            {state.isMobile
              ? vocabulary.playSubPhraseMobile
              : vocabulary.playSubPhrase}
          </span>
        </div>
      </div>
    )
  );
}

export default Start;

import { useContext, useState } from 'react';
import { GameContext } from '../providers/GameProvider';
import { LanguageContext } from '../providers/LanguageProvider';
import styles from '../styles/Diploma.module.css';

function Diploma() {
  const [isVisible, toggleVisibility] = useState(true);
  const { state } = useContext(GameContext);
  const { vocabulary } = useContext(LanguageContext);

  const morphGrade = (grade) => {
    switch (grade) {
      case vocabulary.grades.bachelor:
        return vocabulary.morphGrades.bachelor;
      case vocabulary.grades.magistr:
        return vocabulary.morphGrades.magistr;
      case vocabulary.grades.aspirant:
        return vocabulary.morphGrades.aspirant;
      case vocabulary.grades.candidat:
        return vocabulary.morphGrades.candidat;
      case vocabulary.grades.doctor:
        return vocabulary.morphGrades.doctor;
      default:
        return vocabulary.morphGrades.student;
    }
  };

  return (
    isVisible &&
    state.score > 0 &&
    state.isStarted === false && (
      <div className={styles.diploma}>
        <div className={styles.wrapper}>
          <div className={styles.grade}>
            <div className={styles.stat}>
              <h2 className={styles.diplomaPhrase}>
                {vocabulary.diplomaPhrase}
              </h2>
              <h3 className={styles.gradePhrase}>{morphGrade(state.grade)}</h3>
            </div>
          </div>
          <div className={styles.score}>
            <div className={styles.stat}>
              <h4 className={styles.indexHirsh}>{vocabulary.indexHirsh}</h4>
              <h5 className={styles.scoreCount}>{state.score}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Diploma;

import React, { useContext, useEffect, useState } from 'react';
import { Obstacles } from '../core/Obstacles';
import { LanguageContext } from './LanguageProvider';

export const GameContext = React.createContext();

export function GameProvider(props) {
  const { vocabulary } = useContext(LanguageContext);
  const [grade, setGrade] = useState('');
  const [gender, setGender] = useState(null);
  const [score, setScore] = useState(0);
  const [phrase, setPhrase] = useState('');
  const [playIterations, setPlayIterations] = useState(0);
  const [isStarted, startGame] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 768);
  const [gameContainer, setGameContainer] = useState(null);
  const [restart, setRestart] = useState(null);
  useEffect(() => {
    if (vocabulary) {
      setGrade(vocabulary.grades.student);
      setPhrase(
        vocabulary.Obstacles?.Студент
          ? vocabulary.Obstacles.Студент[0]
          : vocabulary.Obstacles['High school diploma'][0]
      );
    }
    return () => {
      setPhrase('');
      setGrade('');
    };
  }, [vocabulary]);

  const jump = () => {
    let spaceEvent = new KeyboardEvent('keydown', { keyCode: 32, which: 32 });
    document.dispatchEvent(spaceEvent);
  };

  return (
    <GameContext.Provider
      value={{
        state: {
          playIterations,
          score,
          grade,
          gender,
          isStarted,
          phrase,
          isMobile,
          gameContainer,
          restart,
        },
        actions: {
          setGrade,
          setScore,
          setPlayIterations,
          setGender,
          startGame,
          setPhrase,
          jump,
          setGameContainer,
          setRestart,
        },
      }}>
      {props.children}
    </GameContext.Provider>
  );
}
